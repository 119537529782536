.navbarComponent {
  width: 100%;
  /* position: fixed; */
  /* height: var(--navbar-height); */
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  padding-inline: 10px;
  flex-wrap: wrap;
  z-index: 3;
  margin-bottom: 5px;
  gap: 5px;
  border-bottom: 1px solid #ccc;
  background-color: #e0e0e025;
}
.navbarHomeComponent {
  width: 100%;
  position: fixed;
  height: var(--navbar-height);
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  padding-inline: 10px;
  flex-wrap: wrap;
  z-index: 3;
  /* margin-bottom: 5px; */
  gap: 5px;
}
.navbarContainer {
  padding: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.additionalNavItems {
  padding: 5px;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  column-gap: 15px;
  flex-wrap: wrap;
  z-index: 4;
}
.navbarLeft {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
}
.navbarRight {
  display: flex;
  gap: 50px;
}
.iconslist {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: var(--primary-color);
}
.iconslist div {
  cursor: pointer;
}
.iconslist svg {
  width: 24px;
}
.navbarRoutes {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  color: var(--primary-color);
  ul {
    list-style-type: none;
    padding: 0;
  }

  ul li {
    display: inline;
    margin-right: 10px;
  }

  ul li a {
    text-decoration: none;
    color: var(--secondary-color);
    padding: 5px 10px;
  }

  ul li a.active {
    color: var(--primary-color);
  }
}
