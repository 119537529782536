.workInfoContainer {
  display: flex;
  justify-content: start;
  gap: 20px;
  align-items: start;
  flex-wrap: wrap;
  margin: 20px 0px 10px;
  width: 100%;
}
.formSide {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 65%;
  min-width: 320px;
  max-width: 900px;
  border-right: 1px solid var(--secondary-color);
}
.singleRow {
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  min-width: 320px;
  margin-bottom: 5px;
}
.addressInfo {
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-direction: column;
  margin-bottom: 5px;
  width: 100%;
}
.remoteCaption {
  font-size: 16px;
  color: var(--dark-color);
  font-style: italic;
}
.orgChart {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  min-width: 320px;
  max-width: 600px;
}
.hierarchyBox {
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  margin-bottom: 5px;
  width: 90%;
  gap: 20px;
  border-radius: 2px;
  background-color: #7fdf7a7c;
  color: var(--primary-color);
  /* --primary-color: #7fdf7a; */

  padding: 15px;
}
.infoWord {
  font-size: 14px;
}
